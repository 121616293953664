import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";

import { useSelector } from "react-redux";

import { AppState } from "../Store/initialState";

import { Login, Logout, ModuleSalesDocumentNew, ModuleSalesIndex } from "../Views";
import AppFrame from "../Components/AppFrame/AppFrame";

export default function RouterApp () {
    const { isLogged } = useSelector((state: AppState) => state)
    
    const router = createBrowserRouter([
        // Vistas públicas
        {
            path: '/',
            element: !isLogged ? <Login /> : <Navigate to="/sales" />
        },
        // Vistas privadas
        {
            path: '/logout',
            element: <Logout />
        },
        {
            element: isLogged ? <AppFrame /> : <Navigate to="/" />,
            children: [
                {
                    path: '/sales',
                    element: <Navigate to="/sales/documents" />
                },
                {
                    path: '/sales/documents',
                    element: <ModuleSalesIndex />
                },
                {
                    path: '/sales/documents/new',
                    element: <ModuleSalesDocumentNew />
                }
            ]
        }
    ])
    
    return <RouterProvider router={router} />
}