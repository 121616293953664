import { ErrorObjectType } from "../../../Interfaces/Common/ErrorObjectType";
import { initialState } from "../../../Store/initialState";

export default function reduceUserLogout<T>(error?: ErrorObjectType) {
    return {
        ...initialState,
        ...{
            token: null,
            userInfo: null,
            isLogged: false,
            error: error as T
        }
    }
}