import { useState } from "react";
import {
    mdiChevronLeft, mdiChevronRight, mdiFileDocumentMultipleOutline, mdiTextBoxPlusOutline, mdiViewListOutline } from "@mdi/js";
import { Link, useLocation } from "react-router-dom"; 
import Icon from "@mdi/react";
import { Tooltip } from "antd";

import { globals } from "../../../globals";

export default function Suboptions() {
    const [show, setShow] = useState(true)
    const location = useLocation()

    const options = [
        // {
        //     name: 'Clientes',
        //     options: [
        //         { icon: mdiAccountMultiplePlusOutline, text: 'Crear cliente', href: '/sales/clients/new' },
        //         { icon: mdiViewListOutline, text: 'Listado de clientes', href: '/sales/clients' },
        //     ]
        // },
        {
            name: 'Documentos',
            options: [
                { icon: mdiTextBoxPlusOutline, text: 'Emitir documento', href: '/sales/documents/new' },
                { icon: mdiViewListOutline, text: 'Listado de documentos', href: '/sales/documents' },
            ]
        },
        // {
        //     name: 'Configuración',
        //     options: [
        //         { icon: mdiPaletteOutline, text: 'Plantillas', href: '/sales/templates' },
        //         { icon: mdiFaceAgent, text: 'Campos personalizados', href: '/sales/custom-fields' },
        //         { icon: mdiPillar, text: 'Tipos de documentos', href: '/sales/types-documents' },
        //     ]
        // },
    ]

    return <section key={`AppFrame-ModuleSales-Suboptions`} className={`AppFrame--SubOptions AppFrame--SubOptions--${show ? 'Show' : 'Hide'}`}>
        {/* Ocultar y mostrar el menú */}
        <div className={`AppFrame--Hide AppFrame--Hide--${show ? 'Show' : 'Hide'}`} onClick={() => {
            setShow(_v => !_v)
        }}>
            <Icon path={show ? mdiChevronLeft : mdiChevronRight} size={1} color={'#FFF'} />
        </div>
        {show && <h2>
            <Icon path={mdiFileDocumentMultipleOutline} size={1} color={globals.colors.background} />
            Módulo de Ventas
        </h2>}

        {options.map((option, index) => {
            return <div className="AppFrame--Nav--Block" key={`AppFrameMenuBlock${index}`}>
                {show && <h3>{option.name}</h3>}
                <ul>
                    {option.options.map((suboption, _index) => {
                        return <Tooltip key={`Options${index}-${_index}`} placement="right" title={show ? '' : suboption.text}>
                            <li key={`AppFrameMenuBlockSuboption${index}_${_index}`} className={location.pathname === suboption.href ? 'AppFrame--Nav--Block--active' : 'AppFrame--Nav--Block--disabled'}>
                                <Link to={suboption.href}>
                                    <Icon path={suboption.icon} size={show ? 1 : 1.2} />
                                    {show && <span>{suboption.text}</span>}
                                </Link>
                            </li>
                        </Tooltip>
                    })}
                </ul>
            </div>
        })}
    </section>
}