import { gql } from "@apollo/client";

export const UserMe = gql`
query UserMe {
	UserMe {
        state
		user {
            id
            businessId
            email
            firstName
            lastName
            birthDate
			Business {
                id
                name
                licenseUuid
				Documents {
                    id
                    name
                    factorInventory
                    factorVat
                    nextCorrelative
                }
            }
        }
        refreshToken
    }
}
`