import { ErrorObjectType } from "../Interfaces/Common/ErrorObjectType"
import { IUser } from "../Interfaces/IUser"

export type AppState = {
    token?: string
    isLogged?: boolean
    user?: IUser
    error?: ErrorObjectType
}

export const initialState: AppState = {
    token: undefined,
    isLogged: false,
    user: undefined,
    error: undefined
}
