import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES'
import 'dayjs/locale/es-us'

import App from './App';
import reportWebVitals from './reportWebVitals';
import { globals } from './globals';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<>
    {/* @ts-ignore */}
    <ConfigProvider locale={locale} theme={{ token: {
      colorPrimary: globals.colors.primary,
      fontFamily: 'Satoshi-Variable',
      fontSize: 16
    }}}>
      <App />
    </ConfigProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
