import { gql } from "@apollo/client";

export const BusinessFillDocuments = gql`
query BusinessFillDocuments {
	BusinessFillDocuments {
        state
		clients {
            id
            businessId
            legalNumber
            legalName
            address
            mail
            phone
        }
		products {
            id
            businessId
            name
            hasDescription
            hasInventory
        }
    }
}
`