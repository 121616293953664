import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';

// Persistencia - Rehydratation
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Métodos asincrónicos
import thunk from 'redux-thunk'

import rootReducer from './Reducers/'

const persistConfig = {
    key: `${process.env.REACT_APP_STORAGEKEY || 'workiana'}-pkg1`,
    storage,
    // blacklist: ['paymentCartData']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const store = createStore(
        persistedReducer,
        composeWithDevTools(
            applyMiddleware(thunk)
        )
    )

    const persistor = persistStore(store)
    return { store, persistor }
}