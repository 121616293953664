import { Form, Input, Checkbox, Button } from 'antd';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import { Logotipo } from '../../Assets';
import { FieldType } from './types';
import { UserLogin } from '../../Library/UserLogin';
import { doUserLogin } from '../../Store/Actions';

import './Login.scss';

export default function Login() {
    const [login, e_UserLogin] = useMutation(UserLogin)
    const dispatch = useDispatch()
    
    const onFocus = (event: MouseEvent | any) => {
        (event.target as HTMLTextAreaElement).removeAttribute('readonly')
    }

    // Hook: Que se encarga de obtener la respuesta desde la API
    useEffect(() => {
        /**
         * Si la respuesta de la propiedad `state` es "OK", guardamos en la librería de
         * estado de la app el token del usuario.
         */
        if (e_UserLogin?.data?.UserLogin?.state === 'OK') {
            dispatch<any>(doUserLogin({
                token: e_UserLogin?.data?.UserLogin?.token
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e_UserLogin.data])

    // Hook: Escucha errores
    // useEffect(() => {
    //     if (e_UserLogin.error?.message) {
    //         const [code, message] = e_UserLogin.error.message.split('|')
    //         toast.error(<>
    //             <strong>Oops, ha ocurrido un error</strong><br />
    //             {message} <Tooltip zIndex={9999} title={`Cód. Error: ${code}`}>
    //                 <InfoCircleOutlined />
    //             </Tooltip>
    //         </>, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //             // toastId: code
    //         });
    //         console.log(e_UserLogin.error?.message)
    //     }
    // },[e_UserLogin.error])

    const onFinish = (UserLoginInput: any) => {
        login({
            variables: {
                UserLoginInput
            }
        }
        )
        console.log('Success:', UserLoginInput);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return <>
        <h1 className="hide">Oficina en Línea</h1>
        <div className="Login">
            <div className="Login-Grid">
                {/* Left */}
                <section>
                    <div className="align-top">
                        <Logotipo />
                    </div>
                    <h3><strong>Controla tu negocio</strong> desde cualquier lugar y en cualquier momento</h3>
                    <p>Utiliza nuestros módulos de facturación, inventarios, reportería y control de acceso desde cualquier lugar, para tener el control de tu negocio en cualquier momento.</p>
                </section>

                {/* Main section */}
                <main>
                    <h2>Inicio de Sesión</h2>
                    <p>Para comenzar a utilizar oficina<strong>en</strong>línea, inicia sesión con tus credenciales.</p>
                    <Form
                        name="login"
                        layout='vertical'
                        size='large'
                        disabled={e_UserLogin.loading}
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        style={{ maxWidth: '75%' }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            label="Correo electrónico"
                            name="email"
                            rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico válido.' }]}
                        >
                            <Input autoComplete="off" readOnly onFocus={onFocus} />
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Contraseña"
                            name="password"
                            rules={[{ required: true, message: 'Ingresa tu contraseña.' }]}
                        >
                            <Input.Password autoComplete="off" readOnly onFocus={onFocus} />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="remember"
                            valuePropName="checked"
                            wrapperCol={{ offset: 0, span: 16 }}
                        >
                            <Checkbox>Confiar en este dispositivo</Checkbox>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Form>
                </main>
            </div>
        </div>
    </>
}