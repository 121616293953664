import { useEffect, useRef, useState, Fragment } from "react";
import { AutoComplete, Button, DatePicker, Form, Input, Spin, Tooltip } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

import { useForm } from "antd/es/form/Form";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { BusinessFillDocuments } from "../../Library/BusinessFillDocuments";
import { AppState } from "../../Store/initialState";
import { IBusinessClient } from "../../Interfaces/IBusinessClient";
import { IBusinessProduct } from "../../Interfaces/IBusinessProduct";
import { DocumentCreate, DocumentCreateInput } from "../../Library/DocumentCreate";
import Suboptions from "./Elements/Suboptions";
import { DocumentValuesType, FieldType } from "./types";
import ItemRow from "./Elements/ItemRow";

import {
    FIELD_EMISION,
    FIELD_VENCIMIENTO,
    FIELD_RUT,
    FIELD_RAZONSOCIAL,
    FIELD_ATENCIONDE,
    FIELD_DIRECCION,
    FIELD_TELEFONO,
    FIELD_CORREO
} from './constants'

import './ModuleSales.scss'

const nf = new Intl.NumberFormat('es-CL')

export default function ModuleSalesDocumentNew() {
    const { token } = useSelector((state: AppState) => state)

    // const { user } = useOutletContext<{ user: IUser }>()
    const { data, loading: fillLoading, refetch } = useQuery(BusinessFillDocuments, {
        context: {
            headers: {
                Authorization: token
            }
        },
        fetchPolicy: 'no-cache'
    })

    const [create, e_CreateDocument] = useMutation(DocumentCreate, {
        context: {
            headers: {
                authorization: token
            }
        }
    })

    const [clients, setClients] = useState<IBusinessClient[]>([])
    const [products, setProducts] = useState<IBusinessProduct[]>([])

    const inputRefs = useRef([])
    const [form] = useForm()
    const [documentValues, setDocumentValues] = useState<DocumentValuesType>({
        net: 0,
        vat: 0,
        total: 0
    })

    const [loading, setLoading] = useState(true)


    const onFinish = (values: any) => {
        // console.log('Success:', values);
        console.log({ values })
        create({
            variables: {
                DocumentCreateInput: {
                    ...values,
                    emittedAt: dayjs(values.emittedAt).format('YYYY-MM-DD'),
                    expiresAt: dayjs(values.expiresAt).format('YYYY-MM-DD'),
                    businessDocumentId: 1
                } as DocumentCreateInput
            }
        })
    };

    useEffect(() => {
        setLoading(fillLoading || e_CreateDocument.loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillLoading || e_CreateDocument.loading])

    useEffect(() => {
        if (data?.BusinessFillDocuments?.state === 'SUCCESS') {
            setClients(data?.BusinessFillDocuments?.clients)
            setProducts(data?.BusinessFillDocuments?.products)
        }
    }, [data])

    useEffect(() => {
        if (e_CreateDocument.data?.DocumentCreate?.state === 'SUCCESS') {
            refetch()
            form.resetFields()
            setDocumentValues({
                net: 0,
                vat: 0,
                total: 0
            })
            toast.success(<>
                <strong>Documento generado con éxito</strong><br />
                Puedes ver el documento generado haciendo clic en esta notificación.
            </>, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClick: () => {
                    const url = [
                        // navigate(`/sales/documents?action=sendByMail&id=${e_CreateDocument.data.DocumentCreate.businessDTEHeadId}`)
                        process.env.REACT_APP_PDFS_URL || 'http://localhost:4000/pdfs',
                        `${e_CreateDocument.data.DocumentCreate.pdf}.pdf`
                    ]
                    window.open(url.join('/'))
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e_CreateDocument.data])

    return <Fragment key={`ModuleSales`}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Emitir documento | Oficina en Línea</title>
        </Helmet>

        {/* Suboptions */}
        <Suboptions />

        {/* Formulario de Cotización */}
        {loading && <main key={`AppFrame-ModuleSales-Main`} className="AppFrame--Main NoPadding ModuleSales" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spin size='large' />
            <h2 style={{
                margin: 0,
                padding: 0,
                marginTop: 16
            }}>Cargando información...</h2>
        </main>}

        {!loading && <main key={`AppFrame-ModuleSales-Main`} className="AppFrame--Main NoPadding ModuleSales">
            <div className="AppFrame--FiltersBar">
                <Tooltip placement="left" title={!true ? 'Debes completar los campos del documento para emitir.' : ''}>
                    <Button disabled={!true} size='large' type='primary' onClick={() => {
                        form.submit()
                    }}>Generar documento</Button>
                </Tooltip>
            </div>

            <div key={`AppFrame-ModuleSales-Container`} className="ModuleSales--Container">
                <Form<FieldType>
                    name="createDocument"
                    layout="vertical"
                    form={form}
                    initialValues={{
                        // uniqueId: '0000001',
                        emittedAt: dayjs(),
                        expiresAt: dayjs().add(1, 'month'),
                        items: [
                            {
                                name: ''
                            }
                        ]
                    }}
                    scrollToFirstError
                    onFinish={onFinish}
                    onFinishFailed={({ values, errorFields, outOfDate }) => {
                        // Validaremos si en el errorInfo.errorFields viene los items vacíos.
                        if (errorFields.length === 1 && errorFields.find(eF => eF.name.includes('items'))) {
                            toast.error(<>
                                <strong>Oops, ha ocurrido un error</strong><br />
                                El documento debe contener al menos 1 producto o servicio.
                            </>, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }}
                    onValuesChange={(_, values) => {
                        // form.validateFields()
                        // Calcular el total del documento en este momento
                        if (values?.items?.length) {
                            const docValues = values.items.reduce<any>((acc, it) => {
                                if (it?.quantity && it?.total && !isNaN(it.quantity) && !isNaN(it.total)) {
                                    const total = Math.round(it.quantity * it.total)
                                    const net = Math.round(total / 1.19)
                                    const vat = total - net

                                    acc.total += total
                                    acc.net += net
                                    acc.vat += vat
                                }
                                return acc
                            }, {
                                net: 0,
                                vat: 0,
                                total: 0
                            }) as DocumentValuesType

                            setDocumentValues(docValues)
                        }

                        // Verificar si es factible emitir el documento
                        // console.log(form.getFieldsError().some(field => field.errors.length > 0))
                    }}
                    autoComplete="off"
                    size="large"
                >
                    <div className="ModuleSales--Items">
                        {/* <Form.Item<FieldType>
                            label="Correlativo"
                            name="uniqueId"
                            rules={[
                                { required: true, type: 'number', message: 'El correlativo debe ser un número.', transform(value) {
                                    return Number(value)
                                }, }
                            ]}
                        >
                            <Input
                                autoFocus
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_RUT].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_CORRELATIVO] = ref}
                            />
                        </Form.Item> */}
                        <Form.Item<FieldType>
                            label="Fecha de Emisión"
                            name="emittedAt"
                            rules={[
                                { required: true, type: 'date', message: 'Ingresa una fecha válida' }
                            ]}
                        >
                            {/* @ts-ignore */}
                            <DatePicker
                                placeholder="Selecciona una fecha"
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_VENCIMIENTO].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_EMISION] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Fecha de Vencimiento"
                            name="expiresAt"
                            rules={[
                                { required: true, type: 'date', message: 'Ingresa una fecha válida' }
                            ]}
                        >
                            <DatePicker
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_RUT].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_VENCIMIENTO] = ref}
                                placeholder="Selecciona una fecha"
                            />
                        </Form.Item>
                        <div className="clearfix" />
                        <h4>Encabezado del documento</h4>
                        <div className="clearfix" />
                        <Form.Item<FieldType>
                            label="RUT"
                            name="legalNumber"
                            // rules={[
                            //     { required: true, message: 'Ingresa un RUT' }
                            // ]}
                        >
                            <AutoComplete
                                options={clients.map(c => {
                                    return {
                                        label: c.legalNumber,
                                        value: c.legalNumber
                                    }
                                })}
                                onSelect={(value) => {
                                    const client = clients.find(c => c.legalNumber === value)

                                    if (client) {
                                        form.setFieldsValue({
                                            legalNumber: client.legalNumber,
                                            legalName: client.legalName,
                                            address: client.address,
                                            mail: client.mail,
                                            phone: client.phone,
                                        })
                                        // @ts-ignore
                                        inputRefs.current[FIELD_ATENCIONDE].focus();
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_RAZONSOCIAL].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_RUT] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Razón Social"
                            name="legalName"
                            // rules={[
                            //     { required: true, message: 'Ingresa la razón social' }
                            // ]}
                        >
                            <Input
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_ATENCIONDE].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_RAZONSOCIAL] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Atención de"
                            name="legalContactName"
                            rules={[
                                { required: false }
                            ]}
                        >
                            <Input
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_DIRECCION].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_ATENCIONDE] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Dirección"
                            name="address"
                            rules={[
                                { required: false }
                            ]}
                        >
                            <Input
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_TELEFONO].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_DIRECCION] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Teléfono"
                            name="phone"
                            rules={[
                                { required: false }
                            ]}
                        >
                            <Input
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        inputRefs.current[FIELD_CORREO].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_TELEFONO] = ref}
                            />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Correo"
                            name="mail"
                            // rules={[
                            //     { required: true, type: 'email', message: 'Ingresa un correo válido.' }
                            // ]}
                        >
                            <Input
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter') {
                                        console.log(inputRefs)
                                        // @ts-ignore
                                        // inputRefs.current[FIELD_ATENCIONDE].focus();
                                    }
                                }}
                                // @ts-ignore
                                ref={(ref) => inputRefs.current[FIELD_CORREO] = ref}
                            />
                        </Form.Item>

                        <div className="clearfix" />
                        <h4>Detalle de Productos o Servicios</h4>
                        <div className="clearfix" />

                        <Form.List name="items" rules={[
                            {
                                validator(rule, value: any[], callback) {
                                    try {
                                        if (!value.length) {
                                            throw new Error('El documento debe contener al menos un item.')
                                        }
                                    } catch (err: any) {
                                        callback(err);
                                    }
                                    callback()
                                },
                                message: 'El documento debe contener al menos un item.'
                            }
                        ]}>
                            {(fields, { add, remove }) => {
                                return <>
                                    {fields.map((field, index) => (
                                        <ItemRow key={`ItemRow${index}`} field={field} remove={remove} products={products} />
                                    ))}
                                    <Form.Item style={{
                                        gridColumn: '1 / 4',
                                        border: 0
                                    }}>
                                        <Button size="large" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Agregar nueva línea
                                        </Button>
                                    </Form.Item>
                                </>
                            }}
                        </Form.List>


                        <div className="clearfix" />
                        <h4>Comentarios y Pié de Página</h4>
                        <div className="clearfix" />
                        <Form.Item<FieldType> label="Observaciones y Comentarios" name="comments" style={{
                            gridColumn: '1 / 3'
                        }} >
                            <Input.TextArea showCount maxLength={500} style={{
                                border: 0
                            }} />
                        </Form.Item>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            paddingLeft: 16
                        }} className="ModuleSales--Total">
                            <span className="ModuleSales--Total--Label">Neto</span>
                            <span className="ModuleSales--Total--Net">${nf.format(documentValues.net)}</span>
                            <span className="ModuleSales--Total--Label">IVA (19%)</span>
                            <span className="ModuleSales--Total--Vat">${nf.format(documentValues.vat)}</span>
                            <span className="ModuleSales--Total--Label">Total</span>
                            <span className="ModuleSales--Total--Total">${nf.format(documentValues.total)}</span>
                        </div>
                    </div>
                </Form>
            </div>
        </main>}
    </Fragment>
}