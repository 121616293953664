import { AppState, initialState } from "../initialState";
import { reduceUserLogin } from "./User/reduceUserLogin";
import reduceUserLogout from "./User/reduceUserLogout";
import { USER_LOGIN, USER_LOGOUT, USER_PROFILE } from "./states";
import { reduceUserProfile } from "./User/reduceUserProfile";
import { ErrorObjectType } from "../../Interfaces/Common/ErrorObjectType";
import { IUser } from "../../Interfaces/IUser";

export type ActionObject<T> = {
    type: string
    payload: T
}

export type UserProfilePayload = {
    user: IUser
    token?: string
}

export const updateState = (state: AppState, props: any | Partial<AppState>) => Object.assign({}, state, {
    ...props
})

function rootReducer(state = initialState, action: ActionObject<any>) {
    switch (action.type) {
        case USER_LOGIN:
            return reduceUserLogin<Partial<AppState>>(state, action.payload) 
        case USER_LOGOUT:
            return reduceUserLogout<ErrorObjectType>(action.payload.error)
        case USER_PROFILE:
            return reduceUserProfile<UserProfilePayload>(state, action.payload.user, action.payload.token)
    }
    return state
}

export default rootReducer