import { ErrorObjectType } from "../../../Interfaces/Common/ErrorObjectType"

type Payload = {
    error?: ErrorObjectType
}

export function doUserLogout(payload?: Payload) {
    return { type: 'USER_LOGOUT', payload: {
        error: payload?.error
    } }
}
