import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../Store/initialState"
import { useEffect, useState } from "react"

import { doUserLogout, doUserProfile } from "../Store/Actions"
import { useLazyQuery } from "@apollo/client"
import { UserMe } from "../Library/UserMe"

export default function useBusinessUser () {
    const dispatch = useDispatch()
    const { user, token } = useSelector((state: AppState) => state)
    const [data, setData] = useState(user)
    const [getProfile, e_GetProfile] = useLazyQuery(UserMe, {
        context: {
            headers: {
                authorization: `${token}`
            }
        }
    })

    useEffect(() => {
        if (!token) { 
            dispatch(doUserLogout({
                error: {
                    code: 401,
                    message: 'Tu sesión ha caducado, inicia sesión nuevamente.'
                }
            }))
        } else {
            if (!user) {
                getProfile()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (e_GetProfile.data?.UserMe?.state === 'SUCCESS') {
            dispatch<any>(doUserProfile({
                user: e_GetProfile.data?.UserMe?.user,
                refreshToken: e_GetProfile.data?.UserMe?.refreshToken
            }))
            setData(e_GetProfile.data?.UserMe?.user)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e_GetProfile.data])

    return {
        user: data,
        update: getProfile,
        loading: e_GetProfile.loading
    }
}