import { IUser } from "../../../Interfaces/IUser";
import { AppState } from "../../initialState";

import { updateState } from "..";

export function reduceUserProfile<T>(state: AppState, userInfo: IUser, token?: string) {
    return updateState(state, {
        user: userInfo as T,
        token
    })
}