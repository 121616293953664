import { IUser } from "../../../Interfaces/IUser"

export function doUserProfile({ user, refreshToken }: { user: IUser, refreshToken?: string }) {
    return (dispatch: any) => {
        return dispatch({
            type: 'USER_PROFILE',
            payload: {
                user,
                token: refreshToken
            }
        })
    }
}