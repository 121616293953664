import { gql } from "@apollo/client";

export const DocumentList = gql`
query DocumentList {
	DocumentList {
        state
		documents {
            id
            uniqueId
            documentType
            clientLegalName
            clientLegalNumber
            totalPrice
            pdf
        }
    }
}
`