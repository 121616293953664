import { USER_LOGIN } from "../../Reducers/states"
import { AppState } from "../../initialState"

export function doUserLogin({ token }: Partial<AppState>) {
    return (dispatch: any) => {
        dispatch({
            type: USER_LOGIN,
            payload: {
                token,
                isLogged: true
            }
        })
        return true
    }
}