import { useEffect, useState } from "react";
import { Space, Spin, Table } from "antd";
import { Helmet } from "react-helmet";

import './ModuleSales.scss'
import React from "react";
import { FilePdfOutlined } from '@ant-design/icons';

// import { IUser } from "../../Interfaces/IUser";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { AppState } from "../../Store/initialState";
import Suboptions from "./Elements/Suboptions";
import { DocumentList } from "../../Library/DocumentList";

export default function ModuleSalesIndex() {
    const { token } = useSelector((state: AppState) => state)
    const [dataSource, setDataSource] = useState([])
    const { data, loading } = useQuery(DocumentList, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        setDataSource(data?.DocumentList?.documents.map((d: any) => {
            return d
        }))
    }, [data])

    const columns = [
        {
            title: 'Correlativo',
            dataIndex: 'uniqueId',
            key: 'uniqueId',
        },
        {
            title: 'Tipo',
            dataIndex: 'documentType',
            key: 'documentType',
        },
        {
            title: 'Cliente',
            dataIndex: 'clientLegalName',
            key: 'clientLegalName',
        },
        {
            title: 'RUT',
            dataIndex: 'clientLegalNumber',
            key: 'clientLegalNumber',
        },
        {
            title: 'Herramientas',
            key: 'tools',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a target="_blank" href={`${process.env.REACT_APP_PDFS_URL || 'http://localhost:4000/pdfs'}/${record.pdf}`} rel="noreferrer"><FilePdfOutlined /> Descargar</a>
                    {/* <a>Enviar</a> */}
                </Space>
            )
        }
    ];

    return <React.Fragment key={`ModuleSalesIndex`}>
        {/* Cabecera */}
        <Helmet>
            <meta charSet="utf-8" />
            <title>Ventas | Oficina en Línea</title>
        </Helmet>

        {/* Suboptions */}
        <Suboptions />

        {/* Formulario de Cotización */}
        {loading && <main key={`AppFrame-ModuleSales-Main`} className="AppFrame--Main NoPadding ModuleSales" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spin size='large' />
            <h2 style={{
                margin: 0,
                padding: 0,
                marginTop: 16
            }}>Obteniendo información de tu negocio...</h2>
        </main>}

        {/* Tabla general */}
        {!loading && <main key={`AppFrame-ModuleSales-Main`} className="AppFrame--Main NoPadding ModuleSales">
            <div className="AppFrame--FiltersBar">
                {/* <Tooltip placement="left" title={!true ? 'Debes completar los campos del documento para emitir.' : ''}>
                    <Button disabled={!true} size='large' type='primary' onClick={() => {
                        
                    }}>Generar documento</Button>
                </Tooltip> */}
            </div>

            <div key={`AppFrame-ModuleSales-Container`} className="ModuleSales--Container">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    locale={{
                        emptyText: 'No hay documentos emitidos aún.'
                    }}
                    className="ModuleSales__MainTable" />
            </div>
        </main>
        }
    </React.Fragment>
}