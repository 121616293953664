import { gql } from "@apollo/client";

export const DocumentCreate = gql`
mutation DocumentCreate($DocumentCreateInput: DocumentCreateInput!) {
    DocumentCreate(input: $DocumentCreateInput) {
        state
        businessDTEHeadId
        pdf
    }
}
`

export interface DocumentCreateInput {
    businessDocumentId: number
    emmitedAt: string
    expiresAt: string
    legalNumber: string
    legalName: string
    legalContactName?: string
    address?: string
    phone?: string
    mail?: string
    items: {
        quantity: string
        total: string
        name: string
        description?: string
    }[]
    comments?: string
}