import { useEffect, useState } from "react";
import { AutoComplete, Checkbox, Form, FormListFieldData, Input, Tooltip } from "antd";
import { MinusCircleOutlined } from '@ant-design/icons';
import { IBusinessProduct } from "../../../Interfaces/IBusinessProduct";

const nf = new Intl.NumberFormat('es-CL')

export default function ItemRow({ field, remove, products }: {
    field: FormListFieldData
    remove: (index: number | number[]) => void
    products: IBusinessProduct[]
}) {
    const { key, name, ...restField } = field
    const [showDescription, setShowDescription] = useState(false)
    const [_units, setUnits] = useState<number>(0)
    const [_price, setPrice] = useState<number>(0)

    const [_total, setTotal] = useState(0)

    useEffect(() => {
        if (!isNaN(_units) && !isNaN(_price)) {
            setTotal(_units * _price)
        } else {
            setTotal(0)
        }
    }, [_units, _price])

    return <div key={key} className="ModuleSales--ItemRow" style={{ display: 'grid', gridTemplateColumns: '0.5fr 1fr 0.75fr 0.75fr', marginBottom: 8, gridColumn: '1 / 4' }}>
        <Form.Item
            {...restField}
            name={[name, 'quantity']}
            label='Cantidad'
            rules={[
                {
                    required: true, type: 'number', message: 'Ingresa cantidad', transform(value) {
                        return Number(value)
                    },
                }
            ]}
            style={{
                gridColumn: 1
            }}
        >
            <Input onChange={(e) => {
                setUnits(Number(e.target.value))
            }} />
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
                {...restField}
                name={[name, 'name']}
                label='Nombre'
                rules={[
                    { required: true, message: 'Ingresa el nombre del producto' }
                ]}
                style={{
                    flex: 1,
                }}
            >
                <AutoComplete
                    options={products.map(p => {
                        return {
                            label: p.name,
                            value: p.name
                        }
                    })} />
            </Form.Item>
            <Tooltip title="Habilitar descripción">
                <Form.Item
                    {...restField}
                    // name={[name, 'hasDescription']}
                    style={{
                        border: 0,
                        marginLeft: 16
                    }}
                >
                    <Checkbox onChange={(e) => {
                        setShowDescription(e.target.checked)
                    }} />
                </Form.Item>
            </Tooltip>
        </div>
        <Form.Item
            {...restField}
            name={[name, 'total']}
            label='Unitario'
            style={{
                flex: 1
            }}
            rules={[
                {
                    required: true, type: 'number', message: 'Ingresa valor bruto unitario', transform(value) {
                        return Number(value)
                    }
                }
            ]}
        >
            <Input onChange={(e) => {
                setPrice(Number(e.target.value))
            }} />
        </Form.Item>
        <div className="ModuleSales--ItemRow--Finish" style={{ display: 'flex' }}>
            <Form.Item
                {...restField}
                label='Total'
                style={{
                    flex: 1,
                    borderColor: 'transparent'
                }}
            >
                <Input readOnly value={_total === 0 ? '' : nf.format(_total)} />
            </Form.Item>
            <MinusCircleOutlined width={16} style={{
                marginLeft: 8,
                color: '#F00'
            }} onClick={() => remove(name)} />
        </div>
        {showDescription && <Form.Item
            {...restField}
            name={[name, 'description']}
            label='Descripción'
            style={{
                gridColumn: '2 / 5',
                marginRight: 28,
                marginBottom: 16
            }}
        >
            <Input.TextArea showCount maxLength={250} style={{
                border: 0,
            }} />
        </Form.Item>}
    </div>
}