import { Tooltip } from 'antd';
import { mdiAccountCashOutline, mdiFileDocumentMultipleOutline, mdiTruckFastOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Outlet, useNavigate } from 'react-router-dom';

import IconApp from '../../Assets/IconApp';

import './AppFrame.scss';
import useBusinessUser from '../../Hooks/useBusinessUser';

export default function AppFrame() {
    const navigate = useNavigate()
    const { user } = useBusinessUser()

    return <>
        <h1 className="hide">Oficina en Línea</h1>
        <div className="AppFrame">
            {/* Menú lateral */}
            <nav className="AppFrame--Nav">
                {/* Logo de OEL */}
                <IconApp width={48} height={48} onClick={() => {
                    navigate('/')
                }} />
                {/* Módulos disponibles */}
                <ul>
                    <li className="selected">
                        <Tooltip title="Módulo de Ventas" placement={'right'}>
                            <Icon path={mdiFileDocumentMultipleOutline} size={1} color={'#FFF'} />
                        </Tooltip>
                    </li>
                    <li className="disabled">
                        <Tooltip title="Módulo de Inventario" placement={'right'}>
                            <Icon path={mdiTruckFastOutline} size={1} color={'#FFF'} />
                        </Tooltip>
                    </li>
                    <li className="disabled">
                        <Tooltip title="Módulo de Contabilidad" placement={'right'}>
                            <Icon path={mdiAccountCashOutline} size={1} color={'#FFF'} />
                        </Tooltip>
                    </li>
                </ul>
                {/* Avatar / Cierre de sesión */}
                <Tooltip title="Cierre de Sesión" placement={'right'}>
                    {user?.firstName && <div className="AppFrame--Nav--Avatar" onClick={() => {
                        navigate('/logout')
                    }}>
                        {user.firstName[0]}{user.lastName[0]}
                    </div>}
                </Tooltip>
            </nav>

            <Outlet context={[user]} />
        </div>
    </>
}