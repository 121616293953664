import * as React from "react"
import { SVGProps } from "react"

interface IconProps extends SVGProps<SVGSVGElement> {
    iconColor?: string
}

const IconApp = (props: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 48 48"
        fill="none"
        {...props}
    >
        <path
            fill={props.iconColor || '#FFFFFF'}
            fillRule="evenodd"
            d="m24.15 3.343-1.356.407.147.492a2 2 0 0 1-1.341 2.49l-7.41 2.222a2 2 0 0 1-2.49-1.34l-.148-.493-1.357.407a2 2 0 0 0-1.342 2.49l8.594 28.657c.16.535.525.953.983 1.194V12.11a4 4 0 0 1 4-4H37a4 4 0 0 1 4 4v29.918a4 4 0 0 1-4 4H22.43a4 4 0 0 1-4-4v-.037a3.998 3.998 0 0 1-2.899-2.74L6.938 10.592a4 4 0 0 1 2.682-4.98l13.956-4.186a4 4 0 0 1 4.98 2.682l1.2 4h-2.088l-1.027-3.425a2 2 0 0 0-2.49-1.341Zm11.433 6.767H37a2 2 0 0 1 2 2v29.917a2 2 0 0 1-2 2h-1.417v-.514a2 2 0 0 0-2-2h-7.736a2 2 0 0 0-2 2v.514H22.43a2 2 0 0 1-2-2V12.11a2 2 0 0 1 2-2h1.417v.514a2 2 0 0 0 2 2h7.736a2 2 0 0 0 2-2v-.514Z"
            clipRule="evenodd"
        />
    </svg>
)
export default IconApp
