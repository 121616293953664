
import { Spin } from 'antd';
import './Logout.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doUserLogout } from '../../Store/Actions';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            dispatch<any>(doUserLogout())
            navigate('/')
        }, 1000 * 1.5)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return <>
        <h1 className="hide">Oficina en Línea</h1>
        <div className="Logout">
            <Spin size='large' />
            <h2>Estamos cerrando tu sesión.</h2>
            <p>Recuerda que puedes mantener la sesión iniciada en múltiples dispositivos.</p>
        </div>
    </>
}