import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import StoreProvider from "./Store/StoreProvider";
import RouterApp from "./Router";

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // [authLink, errorLink, httpLink]
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      console.log('onError')
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (graphQLErrors) {
        for (const { message } of graphQLErrors) {
          const [code, _message] = message.split('|')
          toast.error(<>
            <strong>Oops, ha ocurrido un error</strong><br />
            {_message} <Tooltip zIndex={9999} title={`Cód. Error: ${code}`}>
              <InfoCircleOutlined />
            </Tooltip>
          </>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // toastId: code
          });
        }
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_ENDPOINT_URL || 'http://localhost:4000/graphql',
      headers: {
        // eslint-disable-next-line no-restricted-globals
        BussinessCodename: location.href ? (new URL(location.href).hostname).split('.')[0] : '',
        // BusinessId: '3'
      }
    }),
  ]),

  headers: {
    /* @todo Automatizar businessId por cada deploy */
    BusinessId: '3'
  },
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
    watchQuery: { errorPolicy: 'all' },
  }
});

function App() {
  return <>
    {/* Proveedor de Apollo GraphQL: Esta es la conexión del FRONT (React) con la API (Datos de Oficina en Línea) */}
    <ApolloProvider client={client}>
      {/* Proveedor de la Biblioteca del Estado de la App: El estado de la App es un "JSON" gigante que tiene información de la App, como datos que ves en pantalla */}
      <StoreProvider>
        {/* Proveedor de la Librería de Rutas de la App: Son las URLs de la App. */}
        <RouterApp />
        <ToastContainer />
      </StoreProvider>
    </ApolloProvider>
  </>
}

export default App;
